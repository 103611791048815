@import "styles/core/_all.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./sweetalert.scss";
// @import "./aos.scss";
@import url("/fonts/fonts.css");
body {
  font-weight: 400;
  color: theme("colors.stone.100");
  background-color: theme("colors.stone.800");
  font-family: "Creato Display", sans-serif;
}

.grecaptcha-badge {
  opacity: 0;
}
