@import "styles/core/_all.scss";
.container {
  position: relative;
  z-index: 30;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
  overflow: hidden;
  .open,
  .close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    span {
      width: 100%;
      height: 6px;
    }
  }
  .open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    span {
      transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      transform-origin: 0 50%;
      background-color: theme("colors.stone.400");

      &:first-child {
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
    }
  }
  .close {
    transform: rotate(-90deg);
    div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        background-color: theme("colors.stone.400");
      }
      &:first-child {
        top: -35%;
        left: 35%;
        transform: translateX(-50%);
        span {
          transform: rotate(-45deg) scaleX(0);
          transform-origin: 100% 50%;
        }
      }
      &:nth-child(2) {
        top: 35%;
        left: -65%;
        transform: translateX(50%);

        span {
          transform: rotate(45deg) scaleX(0);
          transform-origin: 100% 50%;
        }
      }
    }
  }

  &.isActive {
    .open {
      span {
        transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transform: scaleX(0);

        &:first-child {
          transition-delay: 0s;
        }
        &:nth-child(2) {
          transition-delay: 0.1s;
        }
      }
    }
    .close {
      div {
        &:first-child {
          span {
            transform: rotate(-45deg) scaleX(1);
            transition-delay: 0.3s;
          }
        }
        &:nth-child(2) {
          span {
            transform: rotate(45deg) scaleX(1);
            transition-delay: 0.4s;
          }
        }
      }
    }
  }
}
