@import "styles/core/_all.scss";
.container {
  border: 3px solid theme("colors.stone.400");
  border-radius: 999px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  > div {
    display: flex;
    justify-content: space-between;
    height: 40px;
    --boxSize: 4px;
    --gutter: 2px;
    width: calc((var(--boxSize) + var(--gutter)) * 5);
    > div {
      transform: scaleY(0.4);
      height: 100%;
      width: var(--boxSize);
      background: theme("colors.stone.400");
      //   animation-duration: 1.2s;
      //   animation-timing-function: ease-in-out;
      //   animation-iteration-count: infinite;
      //   &:nth-child(1) {
      //     animation-name: extra-quiet;
      //     animation-delay: 0.2s;
      //   }
      //   &:nth-child(2) {
      //     animation-name: normal;
      //   }
      //   &:nth-child(3) {
      //     animation-name: loud;
      //   }
      //   &:nth-child(4) {
      //     animation-name: quiet;
      //     animation-delay: 0.2s;
      //   }
      //   &:nth-child(5) {
      //     animation-name: extra-quiet;
      //   }
    }
  }
}
// @keyframes pause-small {
//   100% {
//     transform: scaleY(0);
//   }
// }
// @keyframes pause-large {
//   100% {
//     transform: scaleY(0.6);
//   }
// }

@keyframes extra-quiet {
  25% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(0.2);
  }
  75% {
    transform: scaleY(0.1);
  }
}
@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.3);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.5);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(0.8);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}
