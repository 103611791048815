@import "styles/core/_all.scss";
.navbar {
  padding: 150px 25px 0 25px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: theme("colors.stone.800");
  // display: none;
  @include media-breakpoint-up(md) {
    padding: 150px 50px 0 50px;
  }
  &.isActive {
    opacity: 1;
    pointer-events: all;
    // ul {
    //   li {
    //     a {
    //       span {
    //         transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);

    //         transform: translateY(0);
    //       }
    //     }
    //   }
    // }
  }
  .inner {
    max-width: theme("maxWidth.screen-lg");
    position: relative;
    height: 100%;
    margin: 0 auto;
    ul {
      display: flex;
      flex-direction: column;
      gap: 30px;

      li {
        list-style: none;
        a {
          display: inline-block;
          overflow: hidden;

          transition: color 0.3s ease-in-out;
          &:hover {
            color: theme("colors.stone.400");
          }
          > span {
            @apply text-2xl font-bold;
            @include media-breakpoint-up(md) {
              @apply text-6xl leading-normal;
            }
            transition: none;
            display: flex;
            align-items: center;
            gap: 8px;
            transform: translateY(100px);
          }
        }
      }
    }
  }
}
