.swal2-container {
  .swal2-popup {
    border-radius: 0;

    .swalAnimate {
      animation-duration: 0.3s;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
  }
}

@keyframes swalFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.swalFadeInUp {
  animation-name: swalFadeInUp;
}

@keyframes swalFadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
  }
}

.swalFadeOutDown {
  animation-name: swalFadeOutDown;
}
